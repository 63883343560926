import React from "react";
import Slider2 from "./Slider2";

function Photos() {
  return (
    <section className="Photos" id="Photos">
      <div className="container">
        <h1 className="titleHeading">
            Photos
        </h1>
        <p>Everything shown in the photos below can be purchased via StrongWood. Contact us for inquiries.</p>
      <Slider2 />
      </div>
    </section>
  );
}

export default Photos;
