import React from "react";
import logo from "../../src/assets/logo.png"

function Home() {
  return (
    <section className="Home" id="Home">
      <div className="container" style={{marginTop: 5 + 'em'}}>
        <div className="row align-items-center">
            <div className="offset-md-1 col-md-6 mt-5">
              <div className="p-3">
                <h1 className="titleHeading">
                  STRONGWOOD
                </h1>
                <p>
                  Insulated Crates, Wooden Boxes & Pallets
                </p>
                <h6>
                  Kelowna, BC
                </h6>
              </div>
            </div>
            <div className="col-md-4 mt-5 text-start">
              <div>
                <img src={logo} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
      </div>
    </section>
  );
}

export default Home;
