import React from "react";
import delivery from "../../src/assets/delivery.png";

function Delivery() {
  return (
    <section className="Delivery" id="Delivery">
      <div className="container">
        <h1 className="titleHeading">
            Local Delivery
        </h1>
        <p className="titleHeading">
            We can deliver to most locations inside the Okanagan Valley Area.
        </p>
        <p className="titleHeading">
            For any delivery questions or concerns please contact us to discuss further details.
        </p>
        <img src={delivery} className="img-fluid" style={{paddingBottom: 1 + 'em'}}/>
      </div>
    </section>
  );
}

export default Delivery;
