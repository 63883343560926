import React from "react";

function Contact() {
  return (
    <section className="Contact" id="Contact">
      <div className="container">
        <h1 className="titleHeading">
          Contact Us
        </h1>
        <h2>
          Email:
        </h2>
        <p>
          s.lapointe@kfaero.ca
        </p>
        <h2>
          Phone:
        </h2>
        <p style={{paddingBottom: 5 + 'em'}}>
          250-864-1711
        </p>
        <p>
          <b>Contact Hours:</b> 7:00am - 9:30pm (7 days per Week)
        </p>
        <p style={{paddingBottom: 5 + 'em'}}>
          <b>Pickup Hours:</b> 7:00am - 3:30pm (Monday - Friday)
        </p>
        <p className="titleHeading">
          For any inquiries regarding purchases, inventory, or deliveries please dont hesitate to call or email.
        </p>
      </div>
    </section>
  );
}

export default Contact;
