import React from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import Inventory from "./components/Inventory";
import Contact from "./components/Contact";
import About from "./components/About";
import Delivery from "./components/Delivery";
import Photos from "./components/Photos";
import Footer from "./components/Footer";

import "./App.css";

function App() {
  return (
    <>
      <Header />
      <Home />
      <About />
      <Photos />
      <Contact />
      <Delivery />
      <Inventory />
    </>
  );
}
export default App;
