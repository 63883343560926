import React from "react";
import "./Team.css"
import box4 from "../../src/assets/box4.png";
import box5 from "../../src/assets/box5.png";
import box6 from "../../src/assets/box6.png";
import pallet from "../../src/assets/pallet.png";

function Team() {
  return (
    <section className="Inventory" id="Inventory">
      <div className="container d-flex justify-content-center">
        <div className="center">
            <h1 className="titleHeading">
            Inventory
            </h1>
            <h2>
              Pick and choose from a wide variety of high quality boxes, crates, and pallets.
            </h2>
          <div className="row">
            <div className="col-md-3">
              <div className="cards">
                <img src={box6} className="img-fluid" style={{paddingBottom: 1 + 'em'}}/>
                <h3>Small Box</h3>     
              </div>
              <h6>24 x 24 x 36</h6>
              <p>$100</p>
            </div>
            <div className="col-md-3">
              <div className="cards">
                <img src={box5} className="img-fluid" style={{paddingBottom: 1 + 'em'}}/>
                <h3>Medium Box</h3>     
              </div>
              <h6>48 x 48 x 96</h6>
              <p>$150</p>
            </div>
            <div className="col-md-3">
              <div className="cards">
                <img src={box4} className="img-fluid" style={{paddingBottom: 1 + 'em'}}/>
                <h3>Large Box</h3>       
              </div>
              <h6>128 x 128 x 256</h6>
              <p>$180</p>
            </div>
            <div className="col-md-3">
              <div className="cards">
                <img src={pallet} className="img-fluid" style={{paddingBottom: 1 + 'em'}}/>
                <h3>Pallets</h3>      
              </div>
              <h6>32 x 32 x 8</h6>
              <p>$15</p>
            </div>
          </div>
          <h4>
              Our inventory is not limited to what's above. This is just an example of the items we do have.
          </h4>
          <h5>Call or Email to inquire.</h5>
          <h2>Refer to the photos below to see more inventory.</h2>
        </div>
        </div>
      </section>
  );
}

export default Team;
