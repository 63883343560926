import React, { useState } from "react";
import "./Header.css";
import logo from "../../src/assets/logo.png"

function Header() {
  const [open, setOpen] = useState(false);
  return (
    <div className="header">
      <div className="left-header">
        <a href="/">
          <img src={logo} alt="" className="logo" />
        </a>
      </div>
      <div className="right-header">
        <nav className={`${open ? "open" : ""}`}>
          <a href="#Home" className="nav-links">
            Home
          </a>
          <a href="#Inventory" className="nav-links">
            Inventory
          </a>
          <a href="#Contact" className="nav-links">
            Contact
          </a>
          <a href="#About" className="nav-links">
            About
          </a>
          <a href="#Delivery" className="nav-links">
            Delivery
          </a>
        </nav>

        <button
          className={`burger ${open ? "active" : ""}`}
          onClick={() => {
            setOpen((prev) => {
              return !prev;
            });
          }}
        ></button>
      </div>
    </div>
  );
}

export default Header;
 