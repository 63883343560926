import React from "react";
import Fade from "react-reveal/Fade";
import home from "../../src/assets/home.png"

function About() {
  return (
    <section className="About" id="About">
      <div className="container">
      <h1 className="titleHeading">About StrongWood</h1>
        <Fade left>
          <div className="row align-items-center mt-5">
            <div className="offset-md-1 col-md-4 mt-5">
              <div>
                <img src={home} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="p-3">
                <p>
                  StrongWood is a small local wood distribution business that specializes in high quality wooden crates, boxes, and pallets for whatever needs and applications you may need.
                </p>
                <p>
                  <b>Contact us for any inquires.</b>
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default About;
