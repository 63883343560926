import React from "react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
// Direct React component imports
// import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "./Swiper";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/effect-coverflow";
// import "swiper/css/pagination";

import "./slider.css";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);

export default function Slider2() {
  const settings = {
    effect: "coverflow",
    pagination: true,
    loop: true,
    centeredSlides: true,
    slidesPerView: 1,
    coverflowEffect: {
      rotate: 0,
      stretch: 50,
      depth: 200,
      modifier: 1.5,
      slideShadows: true,
    },
    className: "swiper-container two",
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 3,
      },
    },
  };
  const data = [...Array(10).keys()];
  return (
    // <Slider settings={settings}>
    //   {data.map((item) => (
    //     <img src="https://picsum.photos/200/300" key={item} alt="swipe" />
    //   ))}
    // </Slider>
    <Swiper {...settings}>
      <SwiperSlide>
        <img src="https://i.postimg.cc/HLd3RrW3/IMG-1055-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/44rxjb51/IMG-1020-2.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/76Cx3Wkv/67848127216-C2-AAFD8-F-E24-A-486-C-AF22-092-CE03-C766-C-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/nhLN9Qxg/IMG-0115-2.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/MKg1VCJw/IMG-0133-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/9Mv93ktV/IMG-0123-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/GptT4RXd/IMG-1045-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/QxDBp1f6/IMG-1051-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/sXFnmTQQ/IMG-9409-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/3xRxBBmZ/IMG-1053-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/zXLgD8PN/IMG-9410-2.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/sX38YnZj/IMG-1054-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/yYKPkJcK/IMG-9252-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/TY4p2vfn/IMG-1018-2.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/65zdqMBV/IMG-9253-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/Cx3dx8nH/IMG-9372-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/pLJXRSxx/IMG-9375-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/6pMJHxt7/IMG-9401-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/nLNW5H5L/IMG-9407-HEIC.png" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://i.postimg.cc/RV9Zy95G/IMG-9436-2.png" />
      </SwiperSlide>
    </Swiper>
  );
}
